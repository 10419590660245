
:root {
  --primary-color: #16103A;
  --secondary-color:#262953;
  --font-color:#fff;
  --green:#0DD355;
  --red:#E7596A;
  --bar-color:#03D1FF;
  --chat-font-color:#374151;
  --container-height:94%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

