.container{
  width:100%;
  background-color: var(--primary-color);
  display: flex;
  color: var(--font-color);
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap:30px;
}

.chatDashBoard{
  display: flex;
  background-color: var(--secondary-color);
  border-radius: 10px;
  min-width: 500px;
  gap:20px;
  height:var(--container-height);
  align-items: center;
}

.chatNotReadDotHolder{
  width: 10px;
  height: 10px;
}

.chatNotReadDot{
  border-radius: 50%;
  background-color: var(--bar-color);
  width: 10px;
  height: 10px;
}

.chatList{
  display: flex;
  flex-direction: column;
  border:1px solid black;
  border-radius: 10px;
  overflow-y: scroll;
  height: 94%;
  margin-left: 10px;
  padding-bottom: 1px;
}




.clickableChatItem{
  cursor: pointer;
  border-bottom:1px solid black;
  display: flex;
  align-items: center;
  gap:5px;
  justify-content: start;
  width: 180px;
  height: 40px;
  padding-left: 6px;
}


.chatCount{
  margin-left: 3px;
  margin-right: 6px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14.5px;
  margin-left: auto;
  color: #666666;
}


.chatAreaHolder{
  display: flex;
  background-color:white;
  width: fit-content;
  margin-right: 20px;
  height:94%;
  border-radius: 3px;
}


.chatArea{
  display: flex;
  flex-direction: column;
  gap:20px;
  justify-content: start;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  min-width:400px;
  max-width: 400px;
  padding: 15px 0;
}


.chatHolder{
  color:#374151;
  font-size:14px;
  font-weight:500;
  width:100%;
  position:relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.chat{
  background-color:#F1F4F7;
  border-radius:8px;
  word-break:break-word;
  overflow-wrap:break-word;
  max-width:65%;
  width:fit-content;
  padding:10px;
  font-weight:500;
  line-height:18px;
  min-height:19px;
  color:var(--chat-font-color);
}

.suggestion{
  border-radius:8px;
  word-break:break-word;
  overflow-wrap:break-word;
  max-width:65%;
  width:fit-content;
  padding:5px;
  font-weight:500;
  line-height:18px;
  min-height:19px;
  color:var(--chat-font-color);
  border:1px solid var(--primary-color);
  cursor: pointer;
  text-align: center;
}

.linkHolder{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:2px;
  flex-direction: column;
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
  font-size: 10px;
}

.center{
  margin:0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:5px;
  font-size: 10px;
}


.chat.user{
  margin-left:auto;
  margin-right:8px;
}

.chat.assistant{
  margin-left:8px;
  margin-right:auto;
}

.suggestionHolder{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:4px;
}

.currentChatInfoHolder{
  display: flex;
  background-color: var(--secondary-color);
  border-radius: 10px;
  gap:10px;
  flex-direction: column;
  height: var(--container-height);
  width: 350px;
  align-items: center;
  overflow-y: scroll;
}

.currentChatInfo:first-child {
  margin-top: 10px;
}

.clickAmount{
  width:16px;
  height:16px;
  font-size: 13px;
  border-radius: 50%;
  background-color: var(--bar-color);
  text-align: center;
}

.currentChatInfo{
  display: flex;
  flex-direction: column;
  gap:4px;
  width: 100%;
  align-items: center;
}

.allChatInfo{
  display: none;
}


.form{
  border:1px solid black;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap:4px;
  max-width: 80%;
  min-width: 80%;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 30px;
  margin-top: 15px;
}
.form textarea{
  resize: none;
  width: 100%;
}

.form input{
  width: 100%;
}

.form button{
  background-color: transparent;
  border:1px solid white;
  border-radius: 10px;
  margin-top: 10px;
  color: white;
  padding: 6px;
  cursor: pointer;

}

.formItem{
  width: 85%;
  display: flex;
  flex-direction: column;
  gap:4px;
}

.userFeedBack {
  display: flex;
  gap:20px;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
}

.userFeedBack div {
  margin-bottom: 5px;
  text-align: center;
}

.userFeedBack span {
  margin-bottom: 5px;
}


.pieChart tspan{
  fill:white;
}
.pieChart text{
  fill:white;
}

.formNotResolved{
  border-radius: 50%;
  color: var(--red);
  font-size: 1.1rem;
  margin-top: 3.5px;
}